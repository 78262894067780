import LoginStore from './LoginStore'
import {runInAction} from 'mobx'

export default async function fetchWithAuth(input: RequestInfo, init?: RequestInit): Promise<Response> {
  if (!init) {
    init = {}
  }

  if (!init.headers) {
    init.headers = new Headers()
  } else {
    init.headers = new Headers(init.headers)
  }
  (init.headers as Headers).set('Authorization', 'Bearer ' + LoginStore.token!!)
  const response = await fetch(input, init)
  if (response.status === 401 || response.status === 403) {
    runInAction(() => {
      LoginStore.token = undefined
    })
  }

  return response
}
