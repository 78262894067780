import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import LoginStore from '../store/LoginStore'
import Fade from '@mui/material/Fade'
import SnackbarContent from '@mui/material/SnackbarContent'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'
import { observer } from 'mobx-react'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import AdminIcon from '@mui/icons-material/SupervisorAccount'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import WaterConsumptionIcon from '@mui/icons-material/Opacity'
import InfoIcon from '@mui/icons-material/Info'
import MeterIcon from '@mui/icons-material/AvTimer'
import UsersIcon from '@mui/icons-material/People'
import WellIcon from '@mui/icons-material/Waves'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import UserStore from '../store/UserStore'
import StatusStore from '../store/StatusStore'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'center',
  },
  error: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f44336',
  },
  success: {
    marginTop: theme.spacing(1),
    backgroundColor: '#4caf50',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}))

export default observer(() => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null as any)
  const onMenuClose = () => setAnchorEl(null)
  return (
    <>
      <Typography
        sx={{ display: { xs: 'none', sm: 'block' }, textAlign: 'right' }}
        component="h1"
        variant="overline"
        className={classes.title}
      >
        Brunnengemeinschaft Südliche Steenbargkoppel
      </Typography>
      <Typography
        sx={{ display: { xs: 'block', sm: 'none' } }}
        component="h1"
        variant="overline"
        className={classes.title}
      >
        Brunnengem. Südl. Steenbargk.
      </Typography>

      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Navigation"
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
            }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">
              {LoginStore.houseNumber === 'admin'
                ? 'Administrator'
                : UserStore.users
                    ?.map((u) => u.lastName)
                    ?.filter((v, i, arr) => arr.indexOf(v, i + 1) === -1)
                    ?.join(' / ')}
            </Typography>
            <Typography variant="caption">
              {LoginStore.houseNumber === 'admin' ? '' : 'Steenbargkoppel ' + LoginStore.houseNumber}
            </Typography>
          </div>
          <div style={{ flex: 1 }} />
          {LoginStore.adminToken && (
            <IconButton
              sx={{ display: { sm: 'none', xs: 'block' } }}
              title="Weiter als Admin"
              color="inherit"
              onClick={() => LoginStore.backToAdmin()}
            >
              <AdminIcon />
            </IconButton>
          )}
          <IconButton
            sx={{ display: { sm: 'none', xs: 'block' } }}
            title="Abmelden"
            color="inherit"
            onClick={() => LoginStore.logout()}
          >
            <LogoutIcon />
          </IconButton>
          {LoginStore.adminToken && (
            <Button
              sx={{ display: { sm: 'block', xs: 'none' } }}
              color="inherit"
              onClick={() => LoginStore.backToAdmin()}
            >
              Weiter als Admin
            </Button>
          )}
          <Button sx={{ display: { sm: 'block', xs: 'none' } }} color="inherit" onClick={() => LoginStore.logout()}>
            Abmelden
          </Button>
        </Toolbar>
      </AppBar>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!anchorEl}
        onClose={onMenuClose}
      >
        {LoginStore.houseNumber !== 'admin' && [
          <MenuItem key="consumption" component={Link} to="/mywater" onClick={onMenuClose}>
            <WaterConsumptionIcon />
            <span style={{ marginLeft: 4 }}>Mein Verbrauch</span>
          </MenuItem>,
          <MenuItem key="meters" component={Link} to="/meters" onClick={onMenuClose}>
            <MeterIcon />
            <span style={{ marginLeft: 4 }}>Meine Zähler</span>
          </MenuItem>,
        ]}
        {LoginStore.houseNumber === 'admin' && [
          <MenuItem key="users" component={Link} to="/users" onClick={onMenuClose}>
            <UsersIcon />
            <span style={{ marginLeft: 4 }}>Benutzer</span>
          </MenuItem>,
          <MenuItem key="well" component={Link} to="/well" onClick={onMenuClose}>
            <WellIcon />
            <span style={{ marginLeft: 4 }}>Brunnenzähler</span>
          </MenuItem>,
        ]}
        <MenuItem key="info" component={Link} to="/info" onClick={onMenuClose}>
          <InfoIcon />
          <span style={{ marginLeft: 4 }}>Informationen</span>
        </MenuItem>
      </Menu>

      <Fade in={!!StatusStore.error} unmountOnExit={true}>
        <SnackbarContent className={classes.error} message={StatusStore.error} />
      </Fade>

      <Fade in={!!StatusStore.message} unmountOnExit={true}>
        <SnackbarContent className={classes.success} message={StatusStore.message} />
      </Fade>
    </>
  )
})
