import React, { useEffect } from 'react'
import './styles.css'
import Login from './component/Login'
import { Route, Routes, useLocation } from 'react-router-dom'
import Default from './component/Default'
import Meters from './component/Meters'
import Users from './component/Users'
import Info from './component/Info'
import Well from './component/Well'
import { observer } from 'mobx-react'
import RoutingStore from './store/RoutingStore'

export default observer(() => {
  const location = useLocation()
  useEffect(() => {
    RoutingStore.location = location
  }, [location])

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/meters" element={<Meters />} />
      <Route path="/mywater" element={<Default />} />
      <Route path="/users" element={<Users />} />
      <Route path="/well" element={<Well />} />
      <Route path="/info" element={<Info />} />
    </Routes>
  )
})
