import { makeAutoObservable } from 'mobx'
import qs, { ParsedQuery } from 'query-string'
import { Location } from 'react-router-dom'

class RoutingStore {
  constructor() {
    makeAutoObservable(this)
  }

  location: Location | undefined

  get parsedQuery(): ParsedQuery | undefined {
    if (this.location) {
      return qs.parse(this.location.search)
    }
    return undefined
  }

  get isUriWithCredentials() {
    return !!(this.parsedQuery && this.parsedQuery.u && this.parsedQuery.p)
  }
}

export default new RoutingStore()
