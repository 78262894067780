import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

export default () => {
  return (
    <CircularProgress
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: 24,
        height: 24,
        marginTop: -12,
        marginLeft: -12,
      }}
      variant="indeterminate"
      disableShrink
      size={24}
      thickness={4}
    />
  )
}
