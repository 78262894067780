import { autorun, makeAutoObservable, runInAction } from 'mobx'
import fetchWithAuth from './fetchWithAuth'
import User from '../model/User'
import LoginStore from './LoginStore'
import StatusStore from './StatusStore'
import handleError from './handleError'

class UserStore {
  users?: User[]

  constructor() {
    makeAutoObservable(this)
    autorun(() => {
      if (LoginStore.loggedInWithToken) {
        this.loadUsers()
      }
    })
  }

  async loadUsers() {
    runInAction(() => {
      StatusStore.startLoading()
      StatusStore.error = undefined
    })
    const response = await fetchWithAuth('/api/users', {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      const users = await response.json()
      runInAction(() => {
        StatusStore.error = undefined
        this.users = users
        StatusStore.finishLoading()
      })
    }
  }

  async saveUser(user: User): Promise<boolean> {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/users' + (user.id ? '/' + user.id : ''), {
      method: user.id ? 'PUT' : 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    })
    if (!response.ok) {
      handleError(response)
      return false
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = 'Der Benutzer wurde erfolgreich gespeichert.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        // reload meter data to update edit box
        this.loadUsers()
      })
    }
    return true
  }

  async deleteUser(user: User) {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/users/' + user.id, {
      method: 'DELETE',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = 'Der Benutzer "' + user.firstName + ' ' + user.lastName + '" wurde gelöscht.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        this.loadUsers()
      })
    }
  }
}

export default new UserStore()
