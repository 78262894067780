import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { create, persist } from 'mobx-persist'
import RoutingStore from './RoutingStore'

class LoginStore {
  hydrated = false
  loading = false
  @persist token?: string = undefined
  @persist adminToken?: string = undefined
  @persist houseNumber?: string = undefined
  @persist error?: string = undefined

  constructor() {
    makeAutoObservable(this)
    autorun(() => {
      if (this.hydrated && RoutingStore.location) {
        if (RoutingStore.isUriWithCredentials) {
          if (this.token && this.houseNumber === 'admin') {
            this.adminToken = this.token
          }
          this.login(RoutingStore.parsedQuery?.u as string, RoutingStore.parsedQuery?.p as string)
        } else if (!this.token) {
          console.log('User not logged in, directing to login page...')
          // not yet logged in, redirect to login
          if (!window.location.pathname.startsWith('/login')) {
            window.location.replace('/login')
          }
        } else {
          console.log('User logged in')
          if (RoutingStore.location?.pathname === '/' || RoutingStore.location?.pathname === '') {
            this.forwardToHome()
          }
        }
      }
    })

    const hydrate = create({})
    hydrate('LoginStore', this).then(() =>
      runInAction(() => {
        this.hydrated = true
      }),
    )
  }

  get loggedInWithToken() {
    if (!!this.token && !!RoutingStore.parsedQuery && !RoutingStore.isUriWithCredentials) return this.token
    return this.token
  }

  backToAdmin() {
    if (this.adminToken) {
      this.houseNumber = 'admin'
      this.token = this.adminToken
      this.adminToken = undefined
      this.forwardToHome()
    }
  }

  forwardToHome() {
    if (this.houseNumber === 'admin') {
      window.location.replace('/users')
    } else {
      window.location.replace('/mywater')
    }
  }

  async login(houseNumber: string, accessCode: string) {
    try {
      this.loading = true
      const response = await fetch('/api/login', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ houseNumber, accessCode }),
      })
      if (response.ok) {
        const token = await response.text()
        console.log('login:', token)
        await runInAction(async () => {
          this.error = undefined
          this.token = token
          this.houseNumber = houseNumber
          this.forwardToHome()
        })
      } else {
        throw Error()
      }
    } catch (e) {
      await runInAction(() => (this.error = 'Login fehlgeschlagen - bitte erneut versuchen'))
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  logout() {
    this.token = undefined
  }
}

export default new LoginStore()
