import React, { useState } from 'react'
import PageHeader from './PageHeader'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import Loading from './Loading'
import StatusStore from '../store/StatusStore'
import WellStore from '../store/WellStore'
import SaveIcon from '@mui/icons-material/Save'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import dayjs from 'dayjs'
import { Container } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
  formElement: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}))

const numberFormat = new Intl.NumberFormat('de-DE')

export default observer(() => {
  const classes = useStyles()
  const [meterCount, setMeterCount] = useState(undefined as string | undefined)
  const [minValue, setMinValue] = useState(undefined as string | undefined)
  const [maxValue, setMaxValue] = useState(undefined as string | undefined)
  const [hystValue, setHystValue] = useState(undefined as string | undefined)
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  if (StatusStore.loading) {
    return <Loading />
  }

  const parsedMeterCount = Number.parseInt(meterCount ?? '0')
  const diff = parsedMeterCount - (WellStore.lastCount?.total ?? 0)

  const min = minValue ?? WellStore.newConfig?.min.toString() ?? WellStore.currentConfig?.min.toString() ?? ''
  const max = maxValue ?? WellStore.newConfig?.max.toString() ?? WellStore.currentConfig?.max.toString() ?? ''
  const hyst = hystValue ?? WellStore.newConfig?.hyst.toString() ?? WellStore.currentConfig?.hyst.toString() ?? ''

  return (
    <Container component="main" maxWidth="lg">
      <PageHeader />

      <Paper className={classes.infoBox}>
        <Typography variant="h6">
          Messwerte{' '}
          {WellStore.samples?.time ? (
            <>
              {dayjs(WellStore.samples.time)
                .locale('de-DE')
                .format('DD.MM. HH:mm')}
            </>
          ) : (
            <i> - keine Messwerte geladen</i>
          )}
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={WellStore.fetchSamples}
                onChange={(_, checked) => WellStore.setCollectValues(checked)}
                color="primary"
              />
            }
            label="Messwerte aktualisieren"
          />
        </FormGroup>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={WellStore.samples?.samples.map((y, x) => ({ x, y: y % 1024 }))}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="x" />
            <YAxis name="Messwert" unit="" />
            <Tooltip
              labelFormatter={(value: number) => numberFormat.format(value)}
              formatter={(value: number) => numberFormat.format(value)}
            />
            <CartesianGrid stroke="#f5f5f5" />
            <Line dataKey="y" dot={false} name="Gemessener Wert" type="monotone" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </Paper>

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Messparameter ändern</Typography>
        <Typography variant="body1">
          Zählung seit Controller-Start:{' '}
          {WellStore.lastCount?.count ? numberFormat.format(WellStore.lastCount.count) : 'unbekannt'}
        </Typography>
        <FormGroup row>
          <TextField
            className={classes.formElement}
            name="minValue"
            type="number"
            label="Niedrigster Wert"
            variant="outlined"
            value={min}
            onChange={e => setMinValue(e.target.value)}
          />
          <TextField
            className={classes.formElement}
            name="maxValue"
            type="number"
            label="Höchster Wert"
            variant="outlined"
            value={max}
            onChange={e => setMaxValue(e.target.value)}
          />
          <TextField
            className={classes.formElement}
            name="maxValue"
            type="number"
            label="Hysterese"
            variant="outlined"
            value={hyst}
            onChange={e => setHystValue(e.target.value)}
          />
        </FormGroup>
        <FormGroup row>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              const minNum = Number.parseInt(min)
              const maxNum = Number.parseInt(max)
              const hystNum = Number.parseInt(hyst)
              if (minNum >= 0 && minNum < maxNum && maxNum <= 1023 && hystNum <= maxNum - minNum) {
                WellStore.setNewConfig({
                  min: minNum,
                  max: maxNum,
                  hyst: hystNum,
                })
                StatusStore.message = 'Die Werte wurden erfolgreich gespeichert.'
              } else {
                StatusStore.error = 'Die Werte sind nicht im erlaubten Bereich: 0 <= min <= max und hyst < max - min.'
              }
            }}
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Speichern
          </Button>
        </FormGroup>
      </Paper>

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Zählerstand ändern</Typography>
        <FormGroup row>
          <TextField
            className={classes.formElement}
            name="count"
            type="number"
            label="Zählerstand (Liter)"
            variant="outlined"
            value={meterCount ?? WellStore.lastCount?.total?.toString() ?? ''}
            onChange={e => setMeterCount(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={
              !WellStore.lastCount?.total ||
              !meterCount?.trim().length ||
              parsedMeterCount === WellStore.lastCount.total
            }
            onClick={() => setConfirmationOpen(true)}
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Speichern
          </Button>
        </FormGroup>
      </Paper>

      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>Zählerstand ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Der Zählerstand wird um {numberFormat.format(diff)} Liter {diff > 0 ? 'erhöht' : 'verringert'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmationOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              WellStore.modifyLastCount(diff)
              setConfirmationOpen(false)
            }}
            color="primary"
          >
            Zählerstand ändern
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
})
