import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React from 'react'
import Copyright from './Copyright'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import DefaultStore from '../store/DefaultStore'
import { observer } from 'mobx-react'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import EditCount from './EditCount'
import PageHeader from './PageHeader'
import Loading from './Loading'
import StatusStore from '../store/StatusStore'

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formElement: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}))

export default observer(() => {
  const classes = useStyles()

  if (StatusStore.loading) {
    return <Loading />
  }

  return (
    <Container component="main" maxWidth="lg">
      <PageHeader />

      {DefaultStore.enabledMeters?.map(meter => {
        const year = DefaultStore.getEditYearForMeter(meter)
        return (
          !!year && (
            <Paper key={meter.id} className={classes.infoBox}>
              <EditCount meter={meter} year={year} showMeterName={DefaultStore.enabledMeters.length > 1} />
            </Paper>
          )
        )
      })}

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Verbrauchsübersicht</Typography>

        <Table aria-label="Wasserverbrauch">
          <TableHead>
            <TableRow>
              <TableCell>Jahr</TableCell>
              {!!DefaultStore.meters && DefaultStore.enabledMeters.length > 1 && <TableCell>Zähler</TableCell>}
              <TableCell align="right">
                Zählerstand (m<sup>3</sup>)
              </TableCell>
              <TableCell align="right">
                Verbrauch (m<sup>3</sup>)
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {DefaultStore.meterCounts?.map(meterCount => (
              <TableRow key={meterCount.id}>
                <TableCell component="th" scope="row">
                  {DefaultStore.getYear(meterCount.date)}
                </TableCell>
                {!!DefaultStore.meters && DefaultStore.enabledMeters.length > 1 && (
                  <TableCell component="th" scope="row">
                    {DefaultStore.meters?.find(m => m.id === meterCount.meterId)?.name}
                  </TableCell>
                )}
                <TableCell align="right">
                  {(meterCount.oldMeterCount ? meterCount.oldMeterCount + '/' : '') + meterCount.count}
                </TableCell>
                <TableCell align="right">{meterCount.consumption}</TableCell>
                <TableCell align="right">
                  {meterCount.allowEdit && (
                    <Tooltip title="Zählerstand löschen und neu erfassen">
                      <IconButton
                        disabled={StatusStore.saving}
                        onClick={() => {
                          DefaultStore.deleteMeterCount(meterCount)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
})
