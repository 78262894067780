import * as React from 'react'
import TextField from '@mui/material/TextField'
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Box from '@mui/material/Box'
import { DateRange } from '@mui/lab/DateRangePicker'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'

interface Props {
  open: boolean | undefined
  onClose: (() => void) | undefined
  onSelect: (dateRange: DateRange<Date>) => void
}

export default function DateRangePickerDialog(props: Props) {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null])

  return (
    <Dialog open={props.open ?? true} onClose={props.onClose} aria-labelledby="draggable-dialog-title">
      <DialogTitle>Zeitraum</DialogTitle>
      <DialogContent>
        <DialogContentText>Zeitraum für Entnahmefilter auswählen</DialogContentText>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateRangePicker
            displayStaticWrapperAs="mobile"
            showToolbar={false}
            allowSameDateSelection
            value={value}
            onChange={(newValue: DateRange<Date>) => {
              setValue(newValue)
            }}
            renderInput={(startProps: any, endProps: any) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onSelect(value)
            props.onClose?.()
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
