import { autorun, makeAutoObservable, runInAction } from 'mobx'
import fetchWithAuth from './fetchWithAuth'
import StatusStore from './StatusStore'
import WellMeterCount from '../model/WellMeterCount'
import LoginStore from './LoginStore'
import dayjs, { Dayjs } from 'dayjs'
import WellMeterSamples from '../model/WellMeterSamples'
import WellMeterConfig from '../model/WellMeterConfig'
import handleError from './handleError'

class WellStore {
  counts?: WellMeterCount[] = undefined
  samples?: WellMeterSamples = undefined
  hourCount: number = 6
  dateFrom: Dayjs | undefined = undefined
  dateTo: Dayjs | undefined = undefined
  startOfYearCount?: WellMeterCount = undefined
  startOfLastYearCount?: WellMeterCount = undefined
  fetchSamples: boolean = false
  currentConfig?: WellMeterConfig
  newConfig?: WellMeterConfig

  get lastCount(): WellMeterCount | undefined {
    if (!this.counts?.length) return undefined
    return this.counts[this.counts.length - 1]
  }

  get useCustomDateRange(): boolean {
    return this.hourCount === 0 && this.dateFrom !== undefined
  }

  get from(): number {
    if (this.useCustomDateRange) {
      return this.dateFrom!!.valueOf()
    }

    return dayjs()
      .subtract(this.hourCount || 6, 'hours')
      .valueOf()
  }

  get to(): number | undefined {
    if (this.useCustomDateRange && this.dateTo !== undefined) {
      return this.dateTo.valueOf()
    }
    return undefined
  }

  constructor() {
    makeAutoObservable(this)
    autorun(() => {
      if (LoginStore.loggedInWithToken) {
        if (this.hourCount > 0 || this.useCustomDateRange) {
          console.log('Load counts...')
          this.loadCounts()
        }
        this.loadSamples()
        this.loadConfigs()
      }
    })
    this.checkForNewSamples()
  }

  checkForNewSamples() {
    const check = async () => {
      if (LoginStore.houseNumber !== 'admin') return
      const response = await fetchWithAuth('/api/well/samples/time', {
        method: 'GET',
        credentials: 'include',
      })
      if (response.ok) {
        const value = await response.json()
        if (
          value.trim().length &&
          (!this.samples?.time || new Date(this.samples.time).getTime() < new Date(value).getTime())
        ) {
          await this.loadSamples()
        }
      }
      setTimeout(check, 10_000)
    }
    setTimeout(check, 10_000)
  }

  async loadConfigs() {
    if (LoginStore.houseNumber !== 'admin') return
    runInAction(() => {
      StatusStore.startLoading()
      StatusStore.error = undefined
    })
    const response = await fetchWithAuth('/api/well/configs', {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) {
      await handleError(response)
    } else {
      const configs = await response.json()
      runInAction(() => {
        StatusStore.error = undefined
        this.currentConfig = configs['current']
        this.newConfig = configs['new']
        StatusStore.finishLoading()
      })
    }
  }

  async loadCounts() {
    runInAction(() => {
      StatusStore.startLoading()
      StatusStore.error = undefined
    })
    try {
      const response = await fetchWithAuth(
        '/api/well/counts' +
          (this.from
            ? '?from=' +
              this.from +
              (this.to
                ? '&to=' +
                  dayjs(this.to)
                    .add(1, 'days')
                    .valueOf()
                : '')
            : ''),
        {
          method: 'GET',
          credentials: 'include',
        },
      )
      if (!response.ok) {
        await handleError(response)
      } else {
        const counts = await response.json()
        runInAction(() => {
          StatusStore.error = undefined
          this.counts = counts
        })
      }
      const [startOfYearResponse, startOfLastYearResponse] = await Promise.all([
        fetchWithAuth(
          '/api/well/counts?from=' +
            dayjs()
              .startOf('year')
              .valueOf() +
            '&max=1',
          {
            method: 'GET',
            credentials: 'include',
          },
        ),
        fetchWithAuth(
          '/api/well/counts?from=' +
            dayjs()
              .startOf('year')
              .subtract(1, 'year')
              .valueOf() +
            '&max=1',
          {
            method: 'GET',
            credentials: 'include',
          },
        ),
      ])
      if (!startOfYearResponse.ok) {
        await handleError(startOfYearResponse)
        return
      } else {
        const counts = await startOfYearResponse.json()
        runInAction(() => {
          StatusStore.error = undefined
          this.startOfYearCount = counts && counts.length ? counts[0] : undefined
        })
      }
      if (!startOfLastYearResponse.ok) {
        await handleError(startOfLastYearResponse)
      } else {
        const counts = await startOfLastYearResponse.json()
        runInAction(() => {
          StatusStore.error = undefined
          this.startOfLastYearCount = counts && counts.length ? counts[0] : undefined
        })
      }
    } finally {
      runInAction(() => {
        StatusStore.finishLoading()
      })
    }
  }

  async loadSamples() {
    if (LoginStore.houseNumber !== 'admin') return
    runInAction(() => {
      StatusStore.startLoading()
      StatusStore.error = undefined
    })
    const response = await fetchWithAuth('/api/well/samples', {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) {
      await handleError(response)
    } else {
      const fetchSamplesResponse = await fetchWithAuth('/api/well/samples/fetchSamples', {
        method: 'GET',
        credentials: 'include',
      })
      if (!fetchSamplesResponse.ok) {
        await handleError(fetchSamplesResponse)
      } else {
        const fetchSamples = await fetchSamplesResponse.json()
        const samples = await response.json()
        runInAction(() => {
          StatusStore.error = undefined
          this.fetchSamples = fetchSamples
          this.samples = samples
          StatusStore.finishLoading()
        })
      }
    }
  }

  async modifyLastCount(diff: number) {
    if (LoginStore.houseNumber !== 'admin') return
    StatusStore.startLoading()
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/well/counts/last/incrementBy', {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(diff),
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.finishLoading()
        this.loadCounts()
      })
    }
  }

  async setCollectValues(collectValues: boolean) {
    if (LoginStore.houseNumber !== 'admin') return
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/well/samples/fetchSamples', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(collectValues),
    })
    if (!response.ok) {
      await handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        this.fetchSamples = collectValues
      })
    }
  }

  async setNewConfig(newConfig: WellMeterConfig) {
    if (LoginStore.houseNumber !== 'admin') return
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/well/configs/new', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newConfig),
    })
    if (!response.ok) {
      await handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        this.newConfig = newConfig
      })
    }
  }
}
export default new WellStore()
