import red from '@mui/material/colors/red'
import { createTheme } from '@mui/material'

// A custom theme for this app
const theme = createTheme({
  spacing: 10,
  palette: {
    primary: {
      main: '#5d69af',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
})

export default theme
