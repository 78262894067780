import { runInAction } from 'mobx'
import StatusStore from './StatusStore'

export default async function handleError(response: Response) {
  const message = await response.text()
  StatusStore.error = undefined
  runInAction(() => {
    if (!message || !message.trim().length) {
      StatusStore.error = 'Fehler: ' + response.statusText + ' ' + response.status + ' - bitte erneut versuchen'
    } else {
      StatusStore.error = message
    }
    StatusStore.finishLoading()
    StatusStore.saving = false
  })
}
