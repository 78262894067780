import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import React from 'react'

export default () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'© ' + new Date().getFullYear() + ' '}
    <Link color="inherit" href="https://www.brunnen-steenbargkoppel.de/">
      www.brunnen-steenbargkoppel.de
    </Link>
    {'.'}
  </Typography>
)
