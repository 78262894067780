import React from 'react'
import Container from '@mui/material/Container'
import PageHeader from './PageHeader'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ImpersonateIcon from '@mui/icons-material/Person'
import { observer } from 'mobx-react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Loading from './Loading'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import User from '../model/User'
import UserStore from '../store/UserStore'
import StatusStore from '../store/StatusStore'
import LoginStore from '../store/LoginStore'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
}))

export default observer(() => {
  const classes = useStyles()
  const [editUser, setEditUser] = React.useState(undefined as User | undefined)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)

  if (StatusStore.loading) {
    return <Loading />
  }

  return (
    <Container component="main" maxWidth="lg">
      <PageHeader />

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Dokumente</Typography>

        <Button onClick={() => window.open('/api/users/letters.pdf?token=' + LoginStore.token, '_blank')}>
          Anschreiben herunterladen
        </Button>
        <Button onClick={() => window.open('/api/users/accounting.pdf?token=' + LoginStore.token, '_blank')}>
          Abrechnungen herunterladen
        </Button>
        <Button onClick={() => window.open('/api/users/usage.pdf?token=' + LoginStore.token, '_blank')}>
          Verbrauchsaufstellung herunterladen
        </Button>
        <Button onClick={() => window.open('/api/users/users.pdf?token=' + LoginStore.token, '_blank')}>
          Mitgliederliste herunterladen
        </Button>
      </Paper>

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Benutzer</Typography>

        <Table aria-label="Benutzer">
          <TableHead>
            <TableRow>
              <TableCell>Hausnummer</TableCell>
              <TableCell>Vorname</TableCell>
              <TableCell>Nachname</TableCell>
              <TableCell>Telefon (privat/gesch.)</TableCell>
              <TableCell>Telefon (mobil)</TableCell>
              <TableCell>Jährliche Vorauszahlung</TableCell>
              <TableCell>Zugangscode</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {UserStore.users?.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.houseNumber}</TableCell>
                <TableCell>
                  {user.gender === 'MALE' ? 'H.' : 'Fr.'} {user.firstName}
                </TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{(user.phonePrivate ?? 'k.A.') + ' / ' + (user.phoneBusiness ?? 'k.A.')}</TableCell>
                <TableCell>{user.phoneMobile}</TableCell>
                <TableCell>{user.advanceYearlyPaymentAmount} EUR</TableCell>
                <TableCell>{user.accessCode}</TableCell>
                <TableCell align="right">
                  {!!user.accessCode && LoginStore.houseNumber === 'admin' && (
                    <Tooltip title="Einloggen als Benutzer">
                      <IconButton
                        disabled={StatusStore.saving}
                        onClick={() => window.location.replace('?u=' + user.houseNumber + '&p=' + user.accessCode)}
                      >
                        <ImpersonateIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Benutzer bearbeiten">
                    <IconButton
                      disabled={StatusStore.saving}
                      onClick={() => {
                        setEditUser(user)
                        setOpenEditDialog(true)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Benutzer entfernen">
                    <IconButton
                      disabled={StatusStore.saving}
                      onClick={() => {
                        UserStore.deleteUser(user)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="right">
                <Tooltip title="Benutzer erstellen">
                  <Fab
                    onClick={() => {
                      setEditUser(undefined)
                      setOpenEditDialog(true)
                    }}
                    color="primary"
                    aria-label="add"
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} aria-labelledby="form-dialog-title">
        <form
          onSubmit={async e => {
            e.preventDefault()
            const form = e.currentTarget as HTMLFormElement
            const id = form.userId && form.userId.value ? Number(form.userId.value) : undefined
            const houseNumber = form.houseNumber.value
            const gender = form.gender.value
            const firstName = form.firstName.value
            const lastName = form.lastName.value
            const phonePrivate = form.phonePrivate.value
            const phoneBusiness = form.phoneBusiness.value
            const phoneMobile = form.phoneMobile.value
            const advanceYearlyPaymentAmount = parseInt(form.advanceYearlyPaymentAmount.value)
            const user: User = {
              id,
              houseNumber,
              gender,
              firstName,
              lastName,
              phonePrivate,
              phoneBusiness,
              phoneMobile,
              advanceYearlyPaymentAmount,
            }
            const success = await UserStore.saveUser(user)
            if (success) {
              setOpenEditDialog(false)
            }
          }}
        >
          {editUser && <input name="userId" type="hidden" value={editUser.id} />}
          <DialogTitle id="form-dialog-title">Benutzer {editUser ? 'bearbeiten' : 'erstellen'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              autoFocus
              margin="dense"
              name="houseNumber"
              label="Hausnummer"
              defaultValue={editUser?.houseNumber || ''}
              fullWidth
            />
            <Select name="gender" defaultValue={editUser?.gender || 'MALE'}>
              <MenuItem value="MALE">Herr</MenuItem>
              <MenuItem value="FEMALE">Frau</MenuItem>
            </Select>
            <TextField
              margin="dense"
              name="firstName"
              label="Vorname"
              defaultValue={editUser?.firstName || ''}
              fullWidth
            />
            <TextField
              required
              margin="dense"
              id="lastName"
              label="Nachname"
              defaultValue={editUser?.lastName || ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="phonePrivate"
              label="Telefon privat"
              defaultValue={editUser?.phonePrivate || ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="phoneBusiness"
              label="Telefon geschäftlich"
              defaultValue={editUser?.phoneBusiness || ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="phoneMobile"
              label="Telefon mobil"
              defaultValue={editUser?.phoneMobile || ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="advanceYearlyPaymentAmount"
              type="number"
              label="Jährliche Vorauszahlung in EUR"
              defaultValue={editUser?.advanceYearlyPaymentAmount || '0'}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={() => {}} color="primary" type="submit">
              Speichern
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  )
})
