import DefaultStore from '../store/DefaultStore'
import React, { useState } from 'react'
import Container from '@mui/material/Container'
import PageHeader from './PageHeader'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { observer } from 'mobx-react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Loading from './Loading'
import { runInAction } from 'mobx'
import StatusStore from '../store/StatusStore'

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
}))

export default observer(() => {
  const classes = useStyles()
  const [editId, setEditId] = useState(undefined as number | undefined)

  if (StatusStore.loading) {
    return <Loading />
  }

  return (
    <Container component="main" maxWidth="lg">
      <PageHeader />

      <Paper className={classes.infoBox}>
        <Typography variant="h6">Wasserzähler</Typography>

        <Table aria-label="Wasserzähler">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {DefaultStore.meters?.map(meter => (
              <TableRow key={meter.id}>
                <TableCell>
                  {editId !== meter.id ? meter.name : ''}
                  {editId === meter.id && (
                    <TextField
                      id={'meterName-' + meter.id}
                      type="text"
                      label="Name"
                      defaultValue={meter.name}
                      autoFocus={true}
                      variant="outlined"
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  {!editId && (
                    <Tooltip title="Zähler bearbeiten">
                      <IconButton
                        disabled={StatusStore.saving}
                        onClick={() => {
                          setEditId(meter.id)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editId === meter.id && (
                    <>
                      <Tooltip title="Abbrechen">
                        <IconButton
                          disabled={StatusStore.saving}
                          onClick={() => {
                            setEditId(undefined)
                            runInAction(() => (StatusStore.error = undefined))
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Speichern">
                        <IconButton
                          disabled={StatusStore.saving}
                          onClick={async () => {
                            const name = (document.getElementById('meterName-' + meter.id) as HTMLInputElement)?.value
                            const success = await DefaultStore.updateMeter({ ...meter, name })
                            if (success) {
                              setEditId(undefined)
                            }
                          }}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {!editId && (
                    <Tooltip title="Zähler entfernen">
                      <IconButton
                        disabled={StatusStore.saving}
                        onClick={() => {
                          DefaultStore.deleteMeter(meter)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {(!DefaultStore.meters || !DefaultStore.meters.length) && (
              <TableRow>
                <TableCell>
                  <i>Noch kein Zähler vorhanden - Bitte erstellen Sie einen Zähler</i>
                </TableCell>
                <TableCell />
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <TextField
                  id="newMeterName"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Zählername"
                  autoComplete="no"
                />
              </TableCell>
              <TableCell align="right">
                <Button
                  color="primary"
                  onClick={() =>
                    DefaultStore.saveMeter({
                      name: (document.getElementById('newMeterName') as HTMLInputElement).value,
                    })
                  }
                >
                  Neuen Zähler erstellen
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  )
})
