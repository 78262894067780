import { observer } from 'mobx-react'
import Loading from './Loading'
import StatusStore from '../store/StatusStore'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import PageHeader from './PageHeader'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import React, { useState } from 'react'
import WellStore from '../store/WellStore'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import dayjs from 'dayjs'
import { action, runInAction } from 'mobx'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import DateRangePickerDialog from './DateRangePickerDialog'

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
}))

const numberFormat = new Intl.NumberFormat('de-DE')

export default observer(() => {
  const classes = useStyles()

  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false)

  if (StatusStore.loading) {
    return <Loading />
  }

  const lastTotal = WellStore.counts?.[(WellStore.counts?.length ?? 1) - 1]?.total ?? 0
  const firstTotal = WellStore.counts?.[0]?.total ?? 0
  const tickFormatter = (value: number) =>
    value
      ? dayjs(value)
          .locale('de-DE')
          .format('DD.MM. HH:mm')
      : ''

  return (
    <Container component="main" maxWidth="lg">
      <PageHeader />

      <Paper className={classes.infoBox}>
        <Typography className={classes.title} variant="h6">
          <span style={{ flex: 1 }}>Wasserentnahme</span>
          <FormControl size="small">
            <Select
              labelId="day-count-label"
              id="day-count-select"
              value={WellStore.hourCount}
              onChange={event => {
                runInAction(() => {
                  WellStore.hourCount = event.target.value as number
                })
              }}
            >
              <MenuItem value={0} onClick={() => setDateRangeDialogOpen(true)}>
                {WellStore.useCustomDateRange && (
                  <>{dayjs(WellStore.dateFrom).format('DD.MM.') + ' - ' + dayjs(WellStore.dateTo).format('DD.MM.')}</>
                )}
                {!WellStore.useCustomDateRange && <>Benutzerdefiniert...</>}
              </MenuItem>
              <MenuItem value={30 * 24}>letzte 30 Tage</MenuItem>
              <MenuItem value={14 * 24}>letzte 14 Tage</MenuItem>
              <MenuItem value={7 * 24}>letzte 7 Tage</MenuItem>
              <MenuItem value={3 * 24}>letzte 3 Tage</MenuItem>
              <MenuItem value={2 * 24}>letzte 2 Tage</MenuItem>
              <MenuItem value={24}>letzte 24 Stunden</MenuItem>
              <MenuItem value={12}>letzte 12 Stunden</MenuItem>
              <MenuItem value={6}>letzte 6 Stunden</MenuItem>
              <MenuItem value={2}>letzte 2 Stunden</MenuItem>
              <MenuItem value={1}>letzte Stunde</MenuItem>
            </Select>
          </FormControl>
          <IconButton size="small" onClick={action(() => WellStore.loadCounts())}>
            <RefreshIcon />
          </IconButton>
        </Typography>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={WellStore.counts} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
            <XAxis dataKey="time" tickFormatter={tickFormatter} />
            <YAxis dataKey="incrementPerMinute" name="Liter/Minute" unit=" l/m" />
            <Tooltip labelFormatter={tickFormatter} formatter={(value: number) => numberFormat.format(value)} />
            <CartesianGrid stroke="#f5f5f5" />
            <Line dot={false} name="Entnahme in l/m" type="monotone" dataKey="incrementPerMinute" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
        <Table aria-label="Fördermengen">
          <TableBody>
            <TableRow>
              <TableCell>
                {WellStore.useCustomDateRange && <strong>Fördermenge im ausgewählten Zeitraum</strong>}
                {!WellStore.useCustomDateRange && (
                  <strong>
                    Fördermenge letzte{' '}
                    {WellStore.hourCount % 24 === 0 && WellStore.hourCount > 24
                      ? WellStore.hourCount / 24 + ' Tage'
                      : WellStore.hourCount + ' Stunden'}
                  </strong>
                )}
              </TableCell>
              <TableCell>{numberFormat.format(lastTotal - firstTotal)}l</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {WellStore.useCustomDateRange && (
                  <strong>
                    Fördermenge Jahresbeginn bis {dayjs(WellStore.dateTo).format('DD.MM.')} {dayjs().year()}
                  </strong>
                )}
                {!WellStore.useCustomDateRange && <strong>Fördermenge seit Jahresbeginn {dayjs().year()}</strong>}
              </TableCell>
              <TableCell>
                {WellStore.startOfYearCount?.total && lastTotal > 0 ? (
                  <>
                    {numberFormat.format(Math.floor((lastTotal - WellStore.startOfYearCount?.total) / 1000))}m
                    <sup>3</sup>
                  </>
                ) : (
                  <>unbekannt</>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>
                  Fördermenge{' '}
                  {dayjs()
                    .subtract(1, 'year')
                    .year()}
                </strong>
              </TableCell>
              <TableCell>
                {WellStore.startOfYearCount?.total && lastTotal > 0 ? (
                  <>
                    {numberFormat.format(
                      Math.floor(
                        ((WellStore.startOfYearCount?.total ?? 0) - (WellStore.startOfLastYearCount?.total ?? 0)) /
                          1000,
                      ),
                    )}
                    m<sup>3</sup>
                  </>
                ) : (
                  <>unbekannt</>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Zählerstand</strong>
              </TableCell>
              <TableCell>
                {lastTotal > 0 && (
                  <>
                    {numberFormat.format(Math.floor(lastTotal / 1000))}m<sup>3</sup>
                  </>
                )}
                {lastTotal === 0 && 'unbekannt'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.infoBox}>
        <Typography variant="h6">Informationen zum Brunnen</Typography>
        <Table aria-label="Informationen zum Brunnen">
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Brunnen-Nummer</strong>
              </TableCell>
              <TableCell>32149</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Aktenzeichen</strong>
              </TableCell>
              <TableCell>841.42-522/016</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Ausbautiefe</strong>
              </TableCell>
              <TableCell>24m</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Max. Fördermenge pro Jahr</strong>
              </TableCell>
              <TableCell>
                1.800m<sup>3</sup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Kostenbeitrag pro Anschluss und Jahr</strong>
              </TableCell>
              <TableCell>50,00 EUR</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>
                  Kostenbeitrag pro m<sup>3</sup>
                </strong>
              </TableCell>
              <TableCell>1,30 EUR</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <DateRangePickerDialog
        open={dateRangeDialogOpen}
        onSelect={dr => {
          const [from, to] = dr
          WellStore.dateFrom = from ? dayjs(from) : undefined
          WellStore.dateTo = to ? dayjs(to) : undefined
        }}
        onClose={() => setDateRangeDialogOpen(false)}
      />
    </Container>
  )
})
