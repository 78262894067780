import { autorun, makeAutoObservable, runInAction } from 'mobx'
import fetchWithAuth from './fetchWithAuth'
import MeterCount from '../model/MeterCount'
import LoginStore from './LoginStore'
import Meter from '../model/Meter'
import StatusStore from './StatusStore'
import handleError from './handleError'

class DefaultStore {
  meterCounts?: MeterCount[]
  meters?: Meter[]

  get enabledMeters(): Meter[] {
    if (!this.meters) {
      return []
    }
    return this.meters.filter(m => !m.disabled)
  }

  constructor() {
    makeAutoObservable(this)

    autorun(() => {
      if (LoginStore.loggedInWithToken) {
        this.loadData()
      }
    })
  }

  getYear(date?: string | Date): number | undefined {
    if (!date) {
      return undefined
    }
    const d = date instanceof Date ? (date as Date) : new Date(date)
    if (d.getMonth() < 2) {
      return d.getFullYear() - 1
    } else {
      return d.getFullYear()
    }
  }

  getEditYearForMeter(meter: Meter): number | undefined {
    const lastMeterCountDate = this.meterCounts?.find(mc => mc.meterId === meter.id)?.date
    const year = this.getYear(lastMeterCountDate)
    if (year == null) {
      const now = new Date()
      return now.getFullYear() - 2
    }
    // check if year is already editable
    const result = year + 1
    if (this.getYear(new Date())! >= result) {
      return result
    }
    return undefined
  }

  async loadData() {
    this.loadMeters()
    await runInAction(async () => await this.loadMeterCounts())
  }

  async loadMeters() {
    runInAction(() => {
      StatusStore.startLoading()
      StatusStore.error = undefined
    })
    const response = await fetchWithAuth('/api/meters', {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      const meters = await response.json()
      runInAction(() => {
        StatusStore.error = undefined
        this.meters = meters
        StatusStore.finishLoading()
      })
    }
  }

  async loadMeterCounts() {
    StatusStore.startLoading()
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meterCounts', {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      const meterCounts = await response.json()
      runInAction(() => {
        StatusStore.error = undefined
        this.meterCounts = meterCounts
        StatusStore.finishLoading()
      })
    }
  }

  async saveMeterCount(meterCount: MeterCount) {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meterCounts', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(meterCount),
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = 'Vielen Dank! Ihr Zählerstand wurde erfolgreich gespeichert.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        // reload meter data to update edit box
        this.loadMeterCounts()
      })
    }
  }

  async deleteMeterCount(meterCount: MeterCount) {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meterCounts/' + meterCount.id, {
      method: 'DELETE',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message =
          'Ihr Zählerstand für ' +
          this.getYear(meterCount.date) +
          ' wurde gelöscht. Bitte erfassen Sie ihn zeitnah neu.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        // reload meter data to update edit box
        this.loadMeterCounts()
      })
    }
  }

  async saveMeter(meter: Meter) {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meters', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(meter),
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = 'Ihr Zähler wurde erfolgreich gespeichert.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        // reload meter data to update edit box
        this.loadMeters()
      })
    }
  }

  async deleteMeter(meter: Meter) {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meters/' + meter.id, {
      method: 'DELETE',
      credentials: 'include',
    })
    if (!response.ok) {
      handleError(response)
    } else {
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = 'Der Zähler "' + meter.name + '" wurde gelöscht.'
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        this.loadMeters()
      })
    }
  }

  async updateMeter(meter: Meter): Promise<boolean> {
    StatusStore.saving = true
    StatusStore.error = undefined
    const response = await fetchWithAuth('/api/meters/' + meter.id, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(meter),
    })
    if (!response.ok) {
      handleError(response)
      return false
    } else {
      const message = await response.text()
      runInAction(() => {
        StatusStore.error = undefined
        StatusStore.message = !message || !message.trim().length ? 'Ihr Zähler wurde erfolgreich gespeichert.' : message
        StatusStore.saving = false
        setTimeout(() => {
          runInAction(() => (StatusStore.message = undefined))
        }, 8000)

        // reload meter data to update edit box
        this.loadMeters()
      })
      return true
    }
  }
}

export default new DefaultStore()
