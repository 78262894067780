import React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import theme from './Theme'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import DefaultStore from './store/DefaultStore'

(window as any).DefaultStore = DefaultStore

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root'),
)
