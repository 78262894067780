import { makeStyles } from '@mui/styles'
import { Box, Button, CircularProgress, Container, CssBaseline, TextField, Theme, Typography } from '@mui/material'
import React, { FormEvent } from 'react'
import Copyright from './Copyright'
import LoginStore from '../store/LoginStore'
import { observer } from 'mobx-react'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default observer(() => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          Mein Wasseranschluss
        </Typography>
        <Typography component="h2" variant="subtitle1" className={classes.title}>
          Brunnengemeinschaft Südliche Steenbargkoppel
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e: FormEvent) => {
            e.preventDefault()
            const form = e.currentTarget as HTMLFormElement
            LoginStore.login(form.housenumber.value, form.accesscode.value)
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Hausnummer"
            name="housenumber"
            autoComplete="no"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="accesscode"
            label="Zugangscode"
            type="password"
            id="password"
            autoComplete="no"
          />
          {LoginStore.error && <div className="error">{LoginStore.error}</div>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={LoginStore.loading}
          >
            {LoginStore.loading ? (
              <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />
            ) : (
              <>Zu meinem Wasseranschluss</>
            )}
          </Button>
          {/*<Grid container>*/}
          {/*  <Grid item xs>*/}
          {/*    <Link href="#" variant="body2">*/}
          {/*      Ich habe keinen Zugangscode*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*  <Grid item>*/}
          {/*    <Link href="#" variant="body2">*/}
          {/*      Wie werde ich Mitglied?*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
})
