import { makeAutoObservable } from 'mobx'

class StatusStore {
  message?: string
  error?: string
  loadCount = 0

  get loading(): boolean {
    return this.loadCount > 0
  }

  saving: boolean = false

  startLoading = () => {
    this.loadCount++
  }

  finishLoading = () => {
    if (this.loadCount > 0) {
      this.loadCount--
    }
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default new StatusStore()
