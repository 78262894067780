import Typography from '@mui/material/Typography'
import DefaultStore from '../store/DefaultStore'
import MeterCount from '../model/MeterCount'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'
import React, { useState } from 'react'
import Meter from '../model/Meter'
import { makeStyles, StyledComponentProps } from '@mui/styles'
import { Theme } from '@mui/material'
import Fade from '@mui/material/Fade'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import StatusStore from '../store/StatusStore'

interface Props extends StyledComponentProps {
  meter: Meter
  year: number
  showMeterName: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  formElement: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f44336',
  },
  success: {
    marginTop: theme.spacing(1),
    backgroundColor: '#4caf50',
  },
}))

export default observer((props: Props) => {
  const classes = useStyles()
  const now = new Date()
  const [oldMeterCountChecked, setOldMeterCountChecked] = useState(false)

  return (
    <>
      <Typography variant="h6">Verbrauch {props.year}</Typography>
      {props.showMeterName && <Typography variant="subtitle1">{props.meter.name}</Typography>}
      <form
        onSubmit={e => {
          e.preventDefault()
          const form = e.currentTarget as HTMLFormElement
          const meterRenewed = form.meterRenewed.checked
          const countStr = form.count.value.replace('.', '')
          const count = Number(countStr)
          const oldMeterCountStr = form.oldMeterCount ? form.oldMeterCount.value.replace('.', '') : undefined
          const oldMeterCount = meterRenewed ? Number(oldMeterCountStr) : undefined
          if (isNaN(count) || !countStr.trim().length) {
            runInAction(
              () => (StatusStore.error = 'Der Zählerstand ist keine gültige Zahl. Bitte überprüfen Sie die Eingabe.'),
            )
          } else if (oldMeterCount !== undefined && (isNaN(oldMeterCount) || !oldMeterCountStr.trim().length)) {
            runInAction(
              () =>
                (StatusStore.error =
                  'Der Stand des alten Zählers ist keine gültige Zahl. Bitte überprüfen Sie die Eingabe.'),
            )
          } else {
            const meterCount: MeterCount = {
              meterId: props.meter.id,
              count,
              date: form.date.value,
              oldMeterCount,
            }
            DefaultStore.saveMeterCount(meterCount)
          }
        }}
      >
        <FormGroup row sx={{ mb: 2 }}>
          <TextField
            sx={{ mr: 2 }}
            className={classes.formElement}
            name="count"
            type="number"
            label={'Zählerstand' + (DefaultStore.enabledMeters.length > 1 ? ' ' + props.meter.name : '')}
            variant="outlined"
          />
          <TextField
            className={classes.formElement}
            style={{ minWidth: 160 }}
            label="Ablesedatum"
            name="date"
            type="date"
            variant="outlined"
            defaultValue={
              DefaultStore.getYear(now) === now.getFullYear()
                ? props.year + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2)
                : props.year + 1 + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2)
            }
          />
        </FormGroup>
        <FormGroup row sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={oldMeterCountChecked}
                name="meterRenewed"
                className={classes.formElement}
                onChange={(_, checked) => {
                  setOldMeterCountChecked(checked)
                }}
              />
            }
            label={'Der Zähler wurde ' + props.year + ' erneuert'}
          />
        </FormGroup>
        <Fade in={oldMeterCountChecked} unmountOnExit={true}>
          <FormGroup row sx={{ mb: 2 }}>
            <TextField
              className={classes.formElement}
              name="oldMeterCount"
              label="Stand des alten Zählers"
              variant="outlined"
              type="number"
            />
          </FormGroup>
        </Fade>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={StatusStore.saving}
          className={classes.button}
          startIcon={<SaveIcon />}
        >
          Speichern
        </Button>
      </form>
    </>
  )
})
